@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');
* {
    /* for debugging overflowing elements */
    /* outline: 1px solid #f00 !important; */
    font-family: "Libre Franklin", sans-serif;

}

.noScrollBarX {
    overflow-x: auto;
    white-space: nowrap;
}

#reviewContent {
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

#reviewContent {
    white-space: pre-line;
}

.fade {
    -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}

#albumCoverResult,
#albumCoverResult img {
    width: 96px;
    max-width: 128px;
}

.albumResultImageContainer img,
.albumResultImageContainer {
    width: 64px;
    max-width: 64px;
}

/* appaz browser zooms if < 16px */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    select,
    textarea,
    input {
        font-size: 16px !important;
    }
}

.list-item-cards {
    display: flex;
    align-content: center;
    max-width: 16em;
    overflow: hidden !important;
}

.list-item-card-wrapper:last-child {
    overflow: visible;
}

.list-item-card-wrapper {
    overflow: hidden;
}

.list-item-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 4em;
    min-width: 4em;
}

body::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari and Opera */
}

body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


#backdropMask {
    text-shadow: 0px 2px 6px rgb(0 0 0 / 40%);
}